<template>
  <CModal
    :title="product.Id ? 'Cập nhật SP' : 'Thêm SP'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="6">
            <CRow>
              <CCol md="12">
                <CInput
                  label="Tên sản phẩm*"
                  placeholder="Tên sản phẩm"
                  v-model="product.Name"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['product.Name'],
                  }"
                  :invalid-feedback="inValidObject['product.Name']"
                />
              </CCol>
              <CCol md="12">
                <CInput
                  label="Tên chung*"
                  placeholder="Tên chung"
                  v-model="product.BaseName"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['product.BaseName'],
                  }"
                  :invalid-feedback="inValidObject['product.BaseName']"
                />
              </CCol>
              <CCol md="6">
                <div role="group" class="form-group">
                  <label>
                    Giá gốc*
                  </label>
                  <money
                    placeholder="Giá gốc"
                    v-model="product.MPrice"
                    class="form-control text-right"
                    :class="{
                      'is-invalid': !!inValidObject['product.MPrice'],
                    }"
                  ></money>
                  <div class="invalid-feedback">
                    {{ inValidObject["product.MPrice"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="6">
                <div role="group" class="form-group">
                  <label>
                    Giá sale*
                  </label>
                  <money
                    placeholder="Giá sale"
                    v-model="product.Price"
                    class="form-control text-right"
                    :class="{
                      'is-invalid': !!inValidObject['product.Price'],
                    }"
                  ></money>
                  <div class="invalid-feedback">
                    {{ inValidObject["product.Price"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="12">
                <CSelect
                  label="Trạng thái*"
                  placeholder="Chọn Trạng thái"
                  :value.sync="product.Status"
                  :options="
                    Object.keys($const.STATUS_TEXT).map((_) => {
                      return {
                        value: _,
                        label: $const.STATUS_TEXT[_],
                      };
                    })
                  "
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['product.Status'],
                  }"
                  :invalid-feedback="inValidObject['product.Status']"
                />
              </CCol>
              <CCol md="12">
                <CSelect
                  label="Phân loại*"
                  placeholder="Chọn Phân loại"
                  :value.sync="product.ProductType"
                  :options="
                    Object.keys($const.PRODUCT_TYPES_TEXT).map((_) => {
                      return {
                        value: _,
                        label: $const.PRODUCT_TYPES_TEXT[_],
                      };
                    })
                  "
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['product.ProductType'],
                  }"
                  :invalid-feedback="inValidObject['product.ProductType']"
                />
              </CCol>
              <CCol md="12">
                <CTextarea
                  label="Mô tả"
                  placeholder="Mô tả"
                  v-model="product.Note"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['product.Note'],
                  }"
                  :invalid-feedback="inValidObject['product.Note']"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6">
            <div class="mb-2">Thông tin bảo hành</div>
            <div class="table-responsive">
              <table class="table table table-striped">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Loại bảo hành</th>
                    <th>Thời gian (tháng)</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    product.ProductWarranties &&
                      product.ProductWarranties.length
                  "
                >
                  <tr
                    v-for="(warranty, i) in product.ProductWarranties"
                    :key="`warranty-${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ warranty.WarrantyName }}</td>
                    <td>
                      <input
                        type="number"
                        v-model="warranty.Months"
                        class="form-control form-control-sm"
                        style="width:100px;"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-danger">
              {{ inValidObject["product.ProductWarranties"] }}
            </div>
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveProduct" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["editing", "productId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      product: {},
      saving: false,
      // money: {
      //   decimal: ",",
      //   thousands: ".",
      //   prefix: "",
      //   suffix: " VND",
      //   precision: 0,
      //   masked: false,
      // },
    };
  },
  mounted() {
    this.product = this.newProduct();
  },
  computed: {
    warrantyTypes() {
      let warranties = this.$const.WARRANTY_TYPES_TEXT;
      return Object.keys(warranties).map((_) => {
        return {
          Id: parseInt(_),
          Name: warranties[_],
        };
      });
    },
  },
  watch: {
    async productId(val) {
      await this.loadProductInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:productId", null);
      }
    },
    detailModal(val) {
      //this.$emit("update:productId", val);
      this.$emit("update:editing", val);
    },
  },
  methods: {
    newProduct() {
      return {
        Id: 0,
        Name: null,
        BaseName: null,
        Note: null,
        MPrice: 0,
        Price: 0,
        Status: this.$const.STATUS.Active.toString(),
        ProductType: this.$const.PRODUCT_TYPES.NewBrand.toString(),
        ProductWarranties: this.warrantyTypes
          ? this.warrantyTypes.map((_) => {
              return {
                Id: 0,
                WarrantyType: _.Id,
                WarrantyName: _.Name,
                Months: 0,
              };
            })
          : [],
      };
    },

    async loadProductInfo(productId) {
      try {
        this.inValidObject = {};
        if (productId) {
          let filters = [];
          productId && filters.push(`Id eq ${productId}`);
          let resp = await this.$http.get(`odata/Product`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,BaseName,MPrice,Price,Note,Status,ProductType",
              $expand: `ProductWarranties($filter=Status eq ${this.$const.STATUS.Active})`,
            },
          });
          if (resp && resp.status == 200) {
            let product = resp.data.value[0];
            this.product = {
              Id: product.Id,
              Name: product.Name,
              BaseName: product.BaseName,
              Note: product.Note,
              MPrice: product.MPrice,
              Price: product.Price,
              Status: product.Status.toString(),
              ProductType: product.ProductType.toString(),
              ProductWarranties: this.warrantyTypes.map((_) => {
                let warranty = product.ProductWarranties.find(
                  (w) => w.WarrantyType == _.Id
                );
                return {
                  Id: warranty ? warranty.Id : 0,
                  WarrantyType: _.Id,
                  WarrantyName: _.Name,
                  Months: warranty ? warranty.Months : 0,
                };
              }),
            };
          }
        } else {
          this.product = this.newProduct();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveProduct() {
      let product = this.product;
      this.inValidObject = this.checkValid(product);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let productData = {
          Name: product.Name,
          BaseName: product.BaseName,
          Note: product.Note,
          MPrice: parseFloat(product.MPrice),
          Price: parseFloat(product.Price),
          Status: parseInt(product.Status),
          Quantity: 1,
          ProductType: parseInt(product.ProductType),
        };
        // post
        if (!product.Id) {
          let resp = await this.$http.post(`odata/Product`, productData);
          if (resp && resp.status == 201) {
            let newProductId = resp.data.Id;

            // post ProductWarranty
            if (product.ProductWarranties && product.ProductWarranties.length) {
              await this.$func.asyncForEach(
                product.ProductWarranties,
                async (productWarranty) => {
                  if (
                    productWarranty.WarrantyType &&
                    productWarranty.Months &&
                    parseInt(productWarranty.Months)
                  ) {
                    await this.$http.post(`odata/ProductWarranty`, {
                      ProductId: newProductId,
                      WarrantyType: productWarranty.WarrantyType,
                      Months: parseInt(productWarranty.Months),
                      Status: this.$const.STATUS.Active,
                    });
                  }
                }
              );
            }
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Product/${product.Id}`,
            productData
          );
          if (resp && resp.status == 204) {
            // post ProductWarranty
            if (product.ProductWarranties && product.ProductWarranties.length) {
              await this.$func.asyncForEach(
                product.ProductWarranties,
                async (productWarranty) => {
                  if (productWarranty.Id) {
                    if (
                      productWarranty.Months &&
                      parseInt(productWarranty.Months)
                    ) {
                      await this.$http.patch(
                        `odata/ProductWarranty/${productWarranty.Id}`,
                        {
                          Months: parseInt(productWarranty.Months),
                        }
                      );
                    } else {
                      await this.$http.delete(
                        `odata/ProductWarranty/${productWarranty.Id}`
                      );
                    }
                  } else if (
                    productWarranty.Months &&
                    parseInt(productWarranty.Months)
                  ) {
                    await this.$http.post(`odata/ProductWarranty`, {
                      ProductId: product.Id,
                      WarrantyType: productWarranty.WarrantyType,
                      Months: parseInt(productWarranty.Months),
                      Status: this.$const.STATUS.Active,
                    });
                  }
                }
              );
            }
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(product) {
      let inValidObject = {};

      if (!product.Name) inValidObject["product.Name"] = "Vui lòng nhập Tên!";
      if (!product.BaseName)
        inValidObject["product.BaseName"] = "Vui lòng nhập Tên chung!";
      if (!product.MPrice)
        inValidObject["product.MPrice"] = "Vui lòng nhập Giá gốc!";
      if (product.Price == null || product.Price == undefined)
        inValidObject["product.Price"] = "Vui lòng nhập Giá sale!";
      if (!product.Status)
        inValidObject["product.Status"] = "Vui lòng chọn Trạng thái!";
      if (!product.ProductType)
        inValidObject["product.ProductType"] = "Vui lòng chọn Phân loại!";

      if (
        product.ProductWarranties &&
        product.ProductWarranties.length &&
        product.ProductWarranties.some(
          (_) => _.Months && parseInt(_.Months) < 0
        )
      ) {
        inValidObject["product.ProductWarranties"] =
          "Thời gian bảo hành không được nhỏ hơn 0!";
      }

      return inValidObject;
    },
  },
};
</script>
