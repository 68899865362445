<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cil-Gift" />
          Sản phẩm
        </span>
        <div class="d-inline-block float-right align-right">
          <CSpinner v-if="exporting" color="primary" size="sm" class="mr-2" />
          <CButton
            v-else
            color="primary"
            size="sm"
            class="mr-2"
            title="export"
            @click.prevent="exportXlsx"
          >
            <CIcon name="cib-experts-exchange" />
          </CButton>

          <CButton
            color="success"
            size="sm"
            class="float-right"
            @click="
              (e) => {
                productId = 0;
                editing = true;
              }
            "
          >
            <CIcon name="cil-plus" />
            Thêm SP
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="3">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="productStatus"
              :options="[
                { value: null, label: 'Tất cả' },
                ...Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="3">
            <CSelect
              label="Phân loại"
              placeholder="Phân loại"
              :value.sync="productType"
              :options="[
                { value: null, label: 'Tất cả' },
                ...Object.keys(productTypeList).map((_) => {
                  return {
                    value: _,
                    label: productTypeList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="3">
            <CInput
              label="Tên sản phẩm"
              placeholder="Tên sản phẩm"
              v-model="productName"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
              @keyup.enter="doFilter"
            />
          </CCol>
          <CCol md="3" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </CRow>
        <odata-table
          ref="lstProduct"
          url="odata/Product"
          :filter="filter"
          select="Id,Name,BaseName,MPrice,Price,Note,ProductType,Status,CreatedAt"
          sortBy="CreatedAt desc"
          :expand="
            `InventoryStocks($select=StockQty;$filter=Status eq ${$const.STATUS.Active})`
          "
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Name: {
              field: 'Name',
              display: `Tên sản phẩm (${$func.addCommas(
                $refs.lstProduct ? $refs.lstProduct.total : 0
              )})`,
              sortable: true,
              style: 'min-width: 120px',
            },
            BaseName: {
              field: 'BaseName',
              display: 'Tên chung',
              sortable: true,
              style: 'min-width: 150px',
            },
            Stock: {
              field: 'Stock',
              display: `Tồn Kho (${$func.addCommas(stockSum)})`,
              sortable: false,
              style: 'min-width: 50px',
            },
            MPrice: {
              field: 'MPrice',
              display: 'Giá gốc',
              sortable: true,
              style: 'min-width: 100px',
            },
            Price: {
              field: 'Price',
              display: 'Giá sale',
              sortable: true,
              style: 'min-width: 100px',
            },
            ProductType: {
              field: 'ProductType',
              display: 'Phân loại',
              sortable: true,
              style: 'min-width: 100px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstProduct.page - 1) * pageSize }}.</td>
              <td class="text-truncate">
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Name}`"
                  @click="
                    (e) => {
                      productId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Name }}
                </a>
              </td>
              <td class="text-truncate" style="max-width:200px;">
                <span :title="row.BaseName">{{ row.BaseName }}</span>
              </td>
              <td>
                <a
                  href="javascript:"
                  v-if="row.InventoryStocks && row.InventoryStocks.length"
                  class="text-primary font-weight-bold"
                  @click="
                    (e) => {
                      stockProductId = row.Id;
                    }
                  "
                >
                  <CIcon name="cil-house" />
                  {{
                    $func.addCommas(
                      row.InventoryStocks.reduce(
                        (cur, a) => cur + a.StockQty,
                        0
                      )
                    )
                  }}
                </a>
              </td>
              <td>
                {{ $func.addCommas(row.MPrice) }}
              </td>
              <td>
                {{ $func.addCommas(row.Price) }}
              </td>
              <td>{{ $const.PRODUCT_TYPES_TEXT[row.ProductType] }}</td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .local()
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :productId.sync="productId"
      @reload="$refs.lstProduct.loadData()"
    />
    <stock :stockProductId.sync="stockProductId" />
  </div>
</template>

<script>
import XLSX from "xlsx";
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";
import stock from "./Stock.vue";

export default {
  name: "Product",
  components: {
    odataTable,
    detail,
    stock,
  },
  data() {
    return {
      editing: false,
      productId: 0,
      pageSize: 10,
      productStatus: null,
      productType: null,
      statusList: this.$const.STATUS_TEXT,
      productTypeList: this.$const.PRODUCT_TYPES_TEXT,
      productName: null,
      filters: null,
      stockProductId: 0,
      exporting: false,
      stockSum: 0,
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  mounted() {
    this.getStockSum();
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
    doFilter() {
      let filters = [];
      this.productStatus && filters.push(`Status eq ${this.productStatus}`);
      this.productType && filters.push(`ProductType eq ${this.productType}`);
      this.productName && filters.push(`contains(Name,'${this.productName}')`);
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstProduct.loadData();
      }
      this.getStockSum();
    },
    clear() {
      this.productStatus = this.$const.STATUS.Active.toString();
      this.productType = null;
      this.productName = null;
    },

    async exportXlsx() {
      let oCpnent = this.$refs.lstProduct;
      if (!oCpnent.total) {
        alert("Không có dữ liệu!");
        return;
      }

      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = [
        `Tên SP (${this.$func.addCommas(oCpnent.total)})`,
        "Tên Chung",
        `Tồn Kho (${this.$func.addCommas(this.stockSum)})`,
        "Giá Gốc",
        "Giá Sale",
        "Phân loại",
        "Trạng Thái",
        "Ngày Tạo",
      ];

      /* XLS Rows Data */
      var xlsRows = [];

      let pageSize = 100;
      var pages =
        oCpnent.total > pageSize ? Math.ceil(oCpnent.total / pageSize) : 1;

      this.exporting = true;
      for (let page = 1; page <= pages; page++) {
        let params = {
          $select: oCpnent.select,
          $expand: oCpnent.expand,
          $orderby: oCpnent.orderBy,
          $top: pageSize,
          $skip: page ? (page - 1) * pageSize : 0,
        };
        if (oCpnent.filter) {
          params.$filter = oCpnent.filter;
        }
        if (oCpnent.extraParams) {
          Object.keys(oCpnent.extraParams).forEach((_) => {
            params[_] = oCpnent.extraParams[_];
          });
        }
        let resp = await this.$http.get(oCpnent.url, {
          params,
        });
        if (
          resp &&
          resp.status == 200 &&
          resp.data.value &&
          resp.data.value.length
        ) {
          let items = resp.data.value;
          xlsRows = [
            ...xlsRows,
            ...items.map((row) => {
              return {
                Name: row.Name,
                BaseName: row.BaseName,
                // Stock:
                //   row.InventoryStocks && row.InventoryStocks.length
                //     ? this.$func.addCommas(
                //         row.InventoryStocks.reduce(
                //           (cur, a) => cur + a.StockQty,
                //           0
                //         )
                //       )
                //     : 0,
                //MPrice: this.$func.addCommas(row.MPrice),
                //Price: this.$func.addCommas(row.Price),
                Stock:
                  row.InventoryStocks && row.InventoryStocks.length
                    ? row.InventoryStocks.reduce(
                        (cur, a) => cur + a.StockQty,
                        0
                      )
                    : 0,
                MPrice: row.MPrice,
                Price: row.Price,
                ProductType: this.$const.PRODUCT_TYPES_TEXT[row.ProductType],
                Status: this.$const.STATUS_TEXT[row.Status],
                CreatedAt: this.$moment
                  .utc(row.CreatedAt)
                  .local()
                  .format("DD/MM/YY HH:mm"),
              };
            }),
          ];
        }
      }
      this.exporting = false;

      if (!xlsRows.length) {
        alert("Không có dữ liệu!");
        return;
      }

      createXLSLFormatObj.push(xlsHeader);
      xlsRows.forEach((value) => {
        var innerRowData = [];
        Object.keys(value).forEach((p) => {
          innerRowData.push(value[p]);
        });
        createXLSLFormatObj.push(innerRowData);
      });

      /* File Name */
      var filename = `products_${this.$moment().format("YYYYMMDD")}.xlsx`;

      /* Sheet Name */
      var ws_name = "Products";

      if (typeof console !== "undefined") console.log(new Date());
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

      /* Add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      /* Write workbook and Download */
      if (typeof console !== "undefined") console.log(new Date());
      XLSX.writeFile(wb, filename);
      if (typeof console !== "undefined") console.log(new Date());
    },

    async getStockSum() {
      let sum = 0;
      let status = this.productStatus;
      let productName = this.productName;
      let productType = this.productType;
      let resp = await this.$http.get(`Report/ProductStockSum`, {
        params: {
          status,
          productType,
          productName,
        },
      });
      if (resp && resp.status == 200 && resp.data && resp.data.length) {
        sum = resp.data[0].stockQty;
      }
      this.stockSum = sum;
    },
  },
};
</script>
