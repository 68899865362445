<template>
  <CModal
    :title="product ? product.Name : ''"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow v-if="product">
          <CCol md="12">
            <div class="mb-2">Thông tin tồn kho</div>
            <div class="table-responsive">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên kho</th>
                    <th class="text-center">Tồn kho</th>
                    <th>Cập nhật</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    product.InventoryStocks && product.InventoryStocks.length
                  "
                >
                  <tr
                    v-for="(stock, i) in product.InventoryStocks"
                    :key="`stock-${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ stock.Warehouse.Name }}</td>
                    <td class="text-center font-weight-bold">
                      <span
                        :class="{
                          'text-success': stock.StockQty > 0,
                          'text-danger': stock.StockQty <= 0 || !stock.StockQty,
                        }"
                      >
                        {{ $func.addCommas(stock.StockQty) }}
                      </span>
                    </td>
                    <td>
                      {{
                        $moment
                          .utc(stock.UpdatedAt)
                          .local()
                          .format("DD/MM/YYYY HH:mm")
                      }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="100%" class="text-center">
                      Tổng tồn kho:
                      {{
                        product.InventoryStocks &&
                        product.InventoryStocks.length
                          ? $func.addCommas(
                              product.InventoryStocks.reduce(
                                (acc, cur) => acc + cur.StockQty,
                                0
                              )
                            )
                          : 0
                      }}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </CCol>
        </CRow>
      </div>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["stockProductId"],
  data() {
    return {
      detailModal: false,
      product: null,
    };
  },
  watch: {
    async stockProductId(val) {
      this.detailModal = !!val;
      await this.loadProductInfo(val);
    },
    detailModal(val) {
      if (!val) {
        this.$emit("update:stockProductId", null);
      }
    },
  },
  methods: {
    async loadProductInfo(productId) {
      try {
        this.product = null;
        if (productId) {
          let filters = [];
          productId && filters.push(`Id eq ${productId}`);
          let resp = await this.$http.get(`odata/Product`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,ProductType",
              $expand: `InventoryStocks(
                  $select=StockQty,UpdatedAt;
                  $expand=Warehouse($select=Name);
                  $orderby=UpdatedAt desc;
              $filter=Status eq ${this.$const.STATUS.Active})`,
            },
          });
          if (resp && resp.status == 200) {
            let product = resp.data.value[0];
            this.product = {
              Name: `${product.Name} - ${
                this.$const.PRODUCT_TYPES_TEXT[product.ProductType]
              }`,
              InventoryStocks: product.InventoryStocks,
            };
          }
        }
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>
