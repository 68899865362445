var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.product.Id ? 'Cập nhật SP' : 'Thêm SP',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"lg"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên sản phẩm*","placeholder":"Tên sản phẩm","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['product.Name'],
                },"invalid-feedback":_vm.inValidObject['product.Name']},model:{value:(_vm.product.Name),callback:function ($$v) {_vm.$set(_vm.product, "Name", $$v)},expression:"product.Name"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên chung*","placeholder":"Tên chung","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['product.BaseName'],
                },"invalid-feedback":_vm.inValidObject['product.BaseName']},model:{value:(_vm.product.BaseName),callback:function ($$v) {_vm.$set(_vm.product, "BaseName", $$v)},expression:"product.BaseName"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Giá gốc* ")]),_c('money',{staticClass:"form-control text-right",class:{
                    'is-invalid': !!_vm.inValidObject['product.MPrice'],
                  },attrs:{"placeholder":"Giá gốc"},model:{value:(_vm.product.MPrice),callback:function ($$v) {_vm.$set(_vm.product, "MPrice", $$v)},expression:"product.MPrice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["product.MPrice"])+" ")])],1)]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Giá sale* ")]),_c('money',{staticClass:"form-control text-right",class:{
                    'is-invalid': !!_vm.inValidObject['product.Price'],
                  },attrs:{"placeholder":"Giá sale"},model:{value:(_vm.product.Price),callback:function ($$v) {_vm.$set(_vm.product, "Price", $$v)},expression:"product.Price"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["product.Price"])+" ")])],1)]),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Trạng thái*","placeholder":"Chọn Trạng thái","value":_vm.product.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                    return {
                      value: _,
                      label: _vm.$const.STATUS_TEXT[_],
                    };
                  }),"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['product.Status'],
                },"invalid-feedback":_vm.inValidObject['product.Status']},on:{"update:value":function($event){return _vm.$set(_vm.product, "Status", $event)}}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Phân loại*","placeholder":"Chọn Phân loại","value":_vm.product.ProductType,"options":Object.keys(_vm.$const.PRODUCT_TYPES_TEXT).map(function (_) {
                    return {
                      value: _,
                      label: _vm.$const.PRODUCT_TYPES_TEXT[_],
                    };
                  }),"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['product.ProductType'],
                },"invalid-feedback":_vm.inValidObject['product.ProductType']},on:{"update:value":function($event){return _vm.$set(_vm.product, "ProductType", $event)}}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Mô tả","placeholder":"Mô tả","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['product.Note'],
                },"invalid-feedback":_vm.inValidObject['product.Note']},model:{value:(_vm.product.Note),callback:function ($$v) {_vm.$set(_vm.product, "Note", $$v)},expression:"product.Note"}})],1)],1)],1),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Thông tin bảo hành")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v("STT")]),_c('th',[_vm._v("Loại bảo hành")]),_c('th',[_vm._v("Thời gian (tháng)")])])]),(
                  _vm.product.ProductWarranties &&
                    _vm.product.ProductWarranties.length
                )?_c('tbody',_vm._l((_vm.product.ProductWarranties),function(warranty,i){return _c('tr',{key:("warranty-" + i)},[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',[_vm._v(_vm._s(warranty.WarrantyName))]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(warranty.Months),expression:"warranty.Months"}],staticClass:"form-control form-control-sm",staticStyle:{"width":"100px"},attrs:{"type":"number"},domProps:{"value":(warranty.Months)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(warranty, "Months", $event.target.value)}}})])])}),0):_vm._e()])]),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.inValidObject["product.ProductWarranties"])+" ")])])],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveProduct}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }