var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cil-Gift"}}),_vm._v(" Sản phẩm ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[(_vm.exporting)?_c('CSpinner',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm"}}):_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm","title":"export"},on:{"click":function($event){$event.preventDefault();return _vm.exportXlsx($event)}}},[_c('CIcon',{attrs:{"name":"cib-experts-exchange"}})],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function (e) {
              _vm.productId = 0;
              _vm.editing = true;
            }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm SP ")],1)],1)]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Trạng thái","value":_vm.productStatus,"options":[
              { value: null, label: 'Tất cả' } ].concat( Object.keys(_vm.statusList).map(function (_) {
                return {
                  value: _,
                  label: _vm.statusList[_],
                };
              }) ),"horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"update:value":function($event){_vm.productStatus=$event}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Phân loại","placeholder":"Phân loại","value":_vm.productType,"options":[
              { value: null, label: 'Tất cả' } ].concat( Object.keys(_vm.productTypeList).map(function (_) {
                return {
                  value: _,
                  label: _vm.productTypeList[_],
                };
              }) ),"horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"update:value":function($event){_vm.productType=$event}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Tên sản phẩm","placeholder":"Tên sản phẩm","horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doFilter($event)}},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}})],1),_c('CCol',{staticClass:"text-center",attrs:{"md":"3"}},[_c('CButton',{staticClass:"mr-2 mb-3",attrs:{"color":"primary"},on:{"click":_vm.doFilter}},[_c('CIcon',{attrs:{"name":"cil-search","custom-classes":"c-icon m-0"}}),_vm._v(" Tìm kiếm ")],1),_c('CButton',{staticClass:"mb-3",attrs:{"color":"secondary"},on:{"click":_vm.clear}},[_c('CIcon',{attrs:{"name":"cil-x-circle","custom-classes":"c-icon m-0"}}),_vm._v(" Đặt lại ")],1)],1)],1),_c('odata-table',{ref:"lstProduct",attrs:{"url":"odata/Product","filter":_vm.filter,"select":"Id,Name,BaseName,MPrice,Price,Note,ProductType,Status,CreatedAt","sortBy":"CreatedAt desc","expand":("InventoryStocks($select=StockQty;$filter=Status eq " + (_vm.$const.STATUS.Active) + ")"),"pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Name: {
            field: 'Name',
            display: ("Tên sản phẩm (" + (_vm.$func.addCommas(
              _vm.$refs.lstProduct ? _vm.$refs.lstProduct.total : 0
            )) + ")"),
            sortable: true,
            style: 'min-width: 120px',
          },
          BaseName: {
            field: 'BaseName',
            display: 'Tên chung',
            sortable: true,
            style: 'min-width: 150px',
          },
          Stock: {
            field: 'Stock',
            display: ("Tồn Kho (" + (_vm.$func.addCommas(_vm.stockSum)) + ")"),
            sortable: false,
            style: 'min-width: 50px',
          },
          MPrice: {
            field: 'MPrice',
            display: 'Giá gốc',
            sortable: true,
            style: 'min-width: 100px',
          },
          Price: {
            field: 'Price',
            display: 'Giá sale',
            sortable: true,
            style: 'min-width: 100px',
          },
          ProductType: {
            field: 'ProductType',
            display: 'Phân loại',
            sortable: true,
            style: 'min-width: 100px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstProduct.page - 1) * _vm.pageSize)+".")]),_c('td',{staticClass:"text-truncate"},[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật: " + (row.Name))},on:{"click":function (e) {
                    _vm.productId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(row.Name)+" ")],1)]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":row.BaseName}},[_vm._v(_vm._s(row.BaseName))])]),_c('td',[(row.InventoryStocks && row.InventoryStocks.length)?_c('a',{staticClass:"text-primary font-weight-bold",attrs:{"href":"javascript:"},on:{"click":function (e) {
                    _vm.stockProductId = row.Id;
                  }}},[_c('CIcon',{attrs:{"name":"cil-house"}}),_vm._v(" "+_vm._s(_vm.$func.addCommas( row.InventoryStocks.reduce( function (cur, a) { return cur + a.StockQty; }, 0 ) ))+" ")],1):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm.$func.addCommas(row.MPrice))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$func.addCommas(row.Price))+" ")]),_c('td',[_vm._v(_vm._s(_vm.$const.PRODUCT_TYPES_TEXT[row.ProductType]))]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .local() .format("DD/MM/YY HH:mm"))+" ")])])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"productId":_vm.productId},on:{"update:editing":function($event){_vm.editing=$event},"update:productId":function($event){_vm.productId=$event},"update:product-id":function($event){_vm.productId=$event},"reload":function($event){return _vm.$refs.lstProduct.loadData()}}}),_c('stock',{attrs:{"stockProductId":_vm.stockProductId},on:{"update:stockProductId":function($event){_vm.stockProductId=$event},"update:stock-product-id":function($event){_vm.stockProductId=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }